// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/roy/_code_/alferex.com/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-all-products-js": () => import("/home/roy/_code_/alferex.com/src/templates/all-products.js" /* webpackChunkName: "component---src-templates-all-products-js" */),
  "component---src-templates-category-products-js": () => import("/home/roy/_code_/alferex.com/src/templates/category-products.js" /* webpackChunkName: "component---src-templates-category-products-js" */),
  "component---src-templates-product-post-js": () => import("/home/roy/_code_/alferex.com/src/templates/product-post.js" /* webpackChunkName: "component---src-templates-product-post-js" */),
  "component---src-pages-404-js": () => import("/home/roy/_code_/alferex.com/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/home/roy/_code_/alferex.com/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-clientele-js": () => import("/home/roy/_code_/alferex.com/src/pages/clientele.js" /* webpackChunkName: "component---src-pages-clientele-js" */),
  "component---src-pages-contact-us-js": () => import("/home/roy/_code_/alferex.com/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-enquiry-js": () => import("/home/roy/_code_/alferex.com/src/pages/enquiry.js" /* webpackChunkName: "component---src-pages-enquiry-js" */),
  "component---src-pages-index-js": () => import("/home/roy/_code_/alferex.com/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-and-terms-js": () => import("/home/roy/_code_/alferex.com/src/pages/privacy-and-terms.js" /* webpackChunkName: "component---src-pages-privacy-and-terms-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/home/roy/_code_/alferex.com/.cache/data.json")

